import React from 'react'
import { CAL_MAX_BUY_LINK } from 'src/utils/constants'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Header } from 'src/utils/styles/calendar'
import { Section, Container, FlexBox, Card, TextContainer, DisclosureContainer } from './styles'
import { CenteredMobile, BuyNowLink } from '../../CalMaxHero/styles'
import { Disclosure, ShopPayImage } from '../ToolTip/styles'
import { Title, Text } from '../../CalendarMaxFeatures/styles'
import { BNPL_FEATURES } from '../ToolTip/constants'

const BnplMax = () => {
  const { shopPayInstallmentsLogo } = useStaticQuery(graphql`
    query BnplMaxQuery {
      shopPayInstallmentsLogo: file(relativePath: { eq: "calmax/shop-pay-installments-logo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <Section>
      <Container>
        <TextContainer>
          <Header>Buy now, pay later for your Calendar Max</Header>
          <Text>
            Choose Shop Pay at checkout to pay in full or to split your purchase into 4 equal
            installments starting at <b>$149.99</b>.*
          </Text>
        </TextContainer>
        <CenteredMobile>
          <BuyNowLink to={CAL_MAX_BUY_LINK}>Shop Calendar Max</BuyNowLink>
        </CenteredMobile>
        <FlexBox>
          {BNPL_FEATURES.map((feature, index) => (
            <Card key={index}>
              <Title>{feature.title}</Title>
              <Text>{feature.text}</Text>
            </Card>
          ))}
        </FlexBox>
        <ShopPayImage>
          <GatsbyImage
            image={shopPayInstallmentsLogo.childImageSharp.gatsbyImageData}
            alt="Shop Pay Installments partnership with affirm logo"
          />
        </ShopPayImage>
        <DisclosureContainer>
          <Disclosure>
            *Rates from 0-36% APR. Payment options through Shop Pay Installments are subject to an
            eligibility check and are provided by these lending partners:{' '}
            <a href="https://affirm.com/lenders" target="_blank" rel="noreferrer">
              [affirm.com/lenders]
            </a>
            . Options depend on your purchase amount, and a down payment may be required. State
            notices to consumers{' '}
            <a href="https://www.affirm.com/licenses" target="_blank" rel="noreferrer">
              [affirm.com/licenses]
            </a>
            .
          </Disclosure>
        </DisclosureContainer>
      </Container>
    </Section>
  )
}

export default BnplMax
