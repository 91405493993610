import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import {
  Section,
  JumbotronImageContainer,
  ContentContainer,
  Title,
  Text,
  CtaContainer,
  CenteredMobile,
  BuyNowLink,
  TitleContainer,
  ShopPayLogo,
} from './styles'

import { CAL_MAX_BUY_LINK } from '../../utils/constants'

const CalMaxHero = () => {
  const { calendarHero, calendarHeroMobile, shopPayInstallmentsLogo } = useStaticQuery(graphql`
    query calMaxHeroQuery {
      calendarHero: file(relativePath: { eq: "calmax/cal-max-hero.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      calendarHeroMobile: file(relativePath: { eq: "calmax/cal-max-hero-mobile.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
      shopPayInstallmentsLogo: file(relativePath: { eq: "calmax/shop-pay-installments-logo.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
        }
      }
    }
  `)
  return (
    <>
      <Section>
        <JumbotronImageContainer>
          <GatsbyImage
            image={calendarHero.childImageSharp.gatsbyImageData}
            alt="Skylight Calendar Max displayed on wall"
            loading="eager"
          />
          <GatsbyImage
            image={calendarHeroMobile.childImageSharp.gatsbyImageData}
            alt="Skylight Calendar Max displayed on wall"
            loading="eager"
          />
        </JumbotronImageContainer>
        <ContentContainer>
          <TitleContainer>
            <Title>Introducing Buy Now, Pay Later</Title>
          </TitleContainer>
          <Text>
            Get your Calendar Max today with flexible payment options through Shop Pay. Choose the
            plan that works for you!
          </Text>
          <CtaContainer>
            <CenteredMobile className="desktopCta">
              <BuyNowLink to={CAL_MAX_BUY_LINK}>Shop Calendar Max</BuyNowLink>
            </CenteredMobile>
          </CtaContainer>
          <ShopPayLogo>
            <GatsbyImage
              image={shopPayInstallmentsLogo.childImageSharp.gatsbyImageData}
              alt="Shop Pay Installments partnership with affirm logo"
            />
          </ShopPayLogo>
        </ContentContainer>
      </Section>
    </>
  )
}

export default CalMaxHero
