import styled from 'styled-components'
import { breakpoints } from 'src/utils/styles/global-styles'

export const Section = styled.div`
  text-align: center;
  background-color: #e5f0f0;
  margin: 0px;
  padding: 56px 0px;
  @media (max-width: ${breakpoints.s}px) {
    padding: 40px 0px 24px;
  }
`

export const FlexBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 48px 0px 16px;
  gap: 32px;
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
    padding: 40px 0px 0px;
    gap: 0px;
    border-radius: 16px;
    text-align: left;
  }
`
export const Card = styled.div`
  flex: 1;
  background-color: #c0dcdc;
  border-radius: 16px;
  padding: 56px 24px;
  @media (max-width: ${breakpoints.l}px) {
    padding: 32px 8px;
  }
  @media (max-width: ${breakpoints.m}px) {
    border-radius: 0px;
    padding: 8px 32px;
  }
  @media (max-width: ${breakpoints.m}px) {
    padding: 4px 32px;
    &:first-child {
      padding-top: 30px;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
    &:last-child {
      padding-bottom: 30px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
  }
`
export const Container = styled.div`
  width: 90%;
  margin: auto;
`
export const TextContainer = styled.div`
  width: 600px;
  margin: auto;
  @media (max-width: ${breakpoints.l}px) {
    width: 400px;
  }
  max-width: 100%;
`
export const DisclosureContainer = styled.div`
  width: 900px;
  margin: auto;
  max-width: 100%;
`
